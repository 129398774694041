import React, { Fragment } from "react";
import { useMediaQuery } from "react-responsive";
import { formatNumber, formatPercent } from "../../../utils/formatHelpers";
import {
  buildLeftMiddleRightRow,
  buildLeftRightRow,
} from "../atoms/layoutAtoms";
import { useCapContext } from "../../providers/CapContext";
import { RowProps } from "../../capTypes";

const SystemRows = ({ viewType, isLoading = true }: RowProps) => {
  const { originalProposal, activeProposal } = useCapContext();
  const isMobile = useMediaQuery({ maxWidth: 750 });

  const data = [
    [
      "Year 1 production",
      `${formatNumber(originalProposal?.year1ProductionForPricing ?? 0)} kWh`,
      `${formatNumber(activeProposal?.year1ProductionForPricing ?? 0)} kWh`,
    ],
    [
      "System size",
      `${originalProposal?.systemSize} kW DC`,
      `${activeProposal?.systemSize} kW DC`,
    ],
    [
      "Est. energy offset",
      `${formatPercent((originalProposal?.usageOffset ?? 0) / 100)}`,
      `${formatPercent((activeProposal?.usageOffset ?? 0) / 100)}`,
    ],
  ];
  let rows = [];
  if (viewType === "Single") {
    rows = data.map((term) => {
      return buildLeftRightRow(term, true, isLoading, isMobile);
    });
  } else {
    rows = data.map((term) => {
      return buildLeftMiddleRightRow(term, true, isLoading, isMobile);
    });
  }
  return (
    <>
      {rows.map((row, i) => (
        <Fragment key={row.key ?? i}>{row}</Fragment>
      ))}
    </>
  );
};

export { SystemRows };
