import React from "react";
import { Card, tokens, Typography } from "@sunrun/experience-ui-components";
import styled from "@emotion/styled/macro";
import { useMediaQuery } from "react-responsive";
import { useCapContext } from "../../providers/CapContext";
import { CarouselWithThumbs } from "../molecules/CarouselWithThumbs";
import { Slide } from "./HomeReadinessStepView";

const EquipmentLocationStepView: React.FC = () => {
  const { equipmentLocationImages } = useCapContext();
  const isMobile = useMediaQuery({ maxWidth: 750 });
  const isTablet = useMediaQuery({ maxWidth: 1024 });

  const slides = equipmentLocationImages?.map((image: any, index: number) => (
    <StyledSlide imageUrl={image.sharinpix__ImageURLFull__c} key={index} />
  ));

  return (
    <>
      <EquipLoc isTablet={isTablet}>
        <EquipmentSidebar isTablet={isTablet}>
          <Typography
            color={tokens.BRAND_HERO_BLUE}
            lineHeight={isTablet ? 32 : 38}
            size={isTablet ? 24 : 32}
            style={{
              marginTop: isMobile ? 16 : isTablet ? 24 : 0,
              marginBottom: isTablet ? 12 : 24,
            }}
          >
            Equipment installation plan
          </Typography>
          <Typography>
            Your installation will require some equipment to be mounted on a
            wall, often near your main electrical panel or electric meter. These
            photos show where the equipment will likely be placed. On
            installation day, your foreperson will review the final locations
            with you.
          </Typography>
        </EquipmentSidebar>
        <ImagesContainer>
          {slides && slides.length > 0 && (
            <Card>
              <CarouselWithThumbs slides={slides} />
            </Card>
          )}
        </ImagesContainer>
      </EquipLoc>
    </>
  );
};

const EquipLoc = styled.div<{ isTablet: boolean }>`
  display: ${({ isTablet }) => (isTablet ? "inherit" : "grid")};
  grid-template-columns: 30% calc(70% - 32px);
  margin-top: ${({ isTablet }) => (isTablet ? "0" : "16px")};
  gap: 32px;
  padding: ${({ isTablet }) => (isTablet ? "0" : "16px 0")};
  min-height: 585px;

  @media (max-width: 750px) {
    grid-template-columns: 100%;
  }
`;

const EquipmentSidebar = styled.div<{ isTablet: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-bottom: ${({ isTablet }) => (isTablet ? "24px" : "0")};
`;

const ImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StyledSlide = styled(Slide)`
  width: var(--slide-size);
`;

export { EquipmentLocationStepView };
