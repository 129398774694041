import React, { ReactNode } from "react";
import { EmblaOptionsType } from "embla-carousel";
import useEmblaCarousel from "embla-carousel-react";
import styled from "@emotion/styled/macro";
import {
  DotButton,
  NextButton,
  PrevButton,
  useDotButton,
  usePrevNextButtons,
} from "../atoms/emblaAtoms";
import { RelativeContainer } from "../atoms/layoutAtoms";

type PropType = {
  slides: ReactNode[];
  options?: EmblaOptionsType;
  showDots?: boolean;
};

const SimpleCarousel: React.FC<PropType> = ({ slides, options, showDots }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(options);

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  return (
    <Embla>
      <RelativeContainer>
        <EmblaButtonContainer slideLength={slides.length}>
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </EmblaButtonContainer>
        <EmblaViewport ref={emblaRef}>
          <CustEmblaContainer slideLength={slides.length}>
            {slides.map((child, index) => (
              <SlideContainer
                onClick={() => onDotButtonClick(index)}
                key={index}
              >
                {child}
              </SlideContainer>
            ))}
          </CustEmblaContainer>
        </EmblaViewport>
      </RelativeContainer>
      {!!showDots && (
        <EmblaDots>
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={"embla__dot".concat(
                index === selectedIndex ? " embla__dot--selected" : ""
              )}
            />
          ))}
        </EmblaDots>
      )}
    </Embla>
  );
};

const IPAD_WIDTH = 750;
const MOBILE_WIDTH = 600;

const SlideContainer = styled.div`
  padding-left: 4px;
  padding-right: 4px;
  flex-shrink: 0;
  width: calc(33% - 5px);

  @media (max-width: ${IPAD_WIDTH}px) {
    width: calc(50% - 16px);
    padding-left: 8px;
    padding-right: 8px;
  }
  @media (max-width: ${MOBILE_WIDTH}px) {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
`;

const Embla = styled.section`
  margin-top: 16px;
`;

const EmblaButtonContainer = styled.div<{ slideLength: number }>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: absolute;
  top: calc(50% - 15px);

  ${({ slideLength }) => (slideLength < 4 ? `display: none;` : "")}

  @media (max-width: ${IPAD_WIDTH}px) {
    ${({ slideLength }) =>
      slideLength < 3 ? `display: none;` : "display: flex;"}
  }
  @media (max-width: ${MOBILE_WIDTH}px) {
    ${({ slideLength }) =>
      slideLength > 1 ? `display: flex;` : "display: none;"}
  }
`;

const EmblaViewport = styled.div`
  overflow: hidden;
`;

const EmblaDots = styled.div`
  margin-top: 8px;
  gap: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CustEmblaContainer = styled.div<{ slideLength: number }>`
  display: flex;

  ${({ slideLength }) => (slideLength < 3 ? `justify-content: center;` : "")}

  @media (max-width: ${IPAD_WIDTH}px) {
    ${({ slideLength }) =>
      slideLength === 2 ? `justify-content: initial;` : ""}
    ${({ slideLength }) => (slideLength < 2 ? `justify-content: center;` : "")}
  }
`;

export { SimpleCarousel };
