import React from "react";
import { useMediaQuery } from "react-responsive";
import {
  buildLeftcolMiddleRightcolRow,
  buildLeftRightColumnRow,
} from "../atoms/layoutAtoms";
import { useCapContext } from "../../providers/CapContext";
import { Asset } from "../../../../amplify/backend/function/OfferExpContacts/ts/public/types";
import { formatNumber } from "../../../utils/formatHelpers";
import { RowProps } from "../../capTypes";

const ProductRows = ({ viewType, isLoading = true }: RowProps) => {
  const { prospect, originalProposal } = useCapContext();
  const isMobile = useMediaQuery({ maxWidth: 750 });

  const activeProposalPanels = prospect?.activeProposal?.assets.find((y: any) =>
    y.type.toLowerCase().includes("panel")
  );
  const originalProposalPanels = originalProposal?.assetInfo.find((y: any) =>
    y.type.toLowerCase().includes("panel")
  );

  const hasSolarPanels = !!activeProposalPanels || !!originalProposalPanels;

  const activeProposalBatteries = prospect?.activeProposal?.assets.filter(
    (asset: Asset) => {
      return (
        asset?.type === "Battery" ||
        asset?.type?.toLowerCase().includes("battery")
      );
    }
  )[0];

  const originalProposalBatteries = originalProposal?.assetInfo.filter(
    (asset: Asset) => {
      return (
        asset.type === "Battery" ||
        asset?.type?.toLowerCase().includes("battery")
      );
    }
  )[0];

  const hasBattery = !!activeProposalBatteries || !!originalProposalBatteries;

  const activeProposalInverters = prospect?.activeProposal?.assets.filter(
    (asset: Asset) => {
      return asset?.type?.toLowerCase().includes("Inverter");
    }
  )[0];

  const originalProposalInverters = originalProposal?.assetInfo.filter(
    (asset: Asset) => {
      return asset?.type?.toLowerCase().includes("Inverter");
    }
  )[0];

  const hasInverter = !!activeProposalInverters || !!originalProposalInverters;

  const activeProposalSpan = prospect?.activeProposal?.assets.filter(
    (asset: any) => {
      return asset.manufacturer.toLowerCase().includes("span");
    }
  )[0];

  const originalProposalSpan = originalProposal?.assetInfo.filter(
    (asset: any) => {
      return asset.manufacturer.toLowerCase().includes("span");
    }
  )[0];

  const hasSmartPanels = !!activeProposalSpan || !!originalProposalSpan;

  const productTypes = [
    hasSolarPanels,
    hasBattery,
    hasInverter,
    hasSmartPanels,
  ];
  let productCount = 0;

  productTypes.forEach((prodType) => {
    if (prodType) {
      productCount++;
    }
  });

  const rows = [];
  if (hasSolarPanels) {
    let underline = productCount > 1;
    const solarTuple = [
      "Panels",
      `${formatNumber(originalProposalPanels?.count ?? 0, 0)}`,
      `${originalProposalPanels?.model}`,
      `${formatNumber(activeProposalPanels?.count ?? 0, 0)}`,
      `${activeProposalPanels?.model}`,
    ];
    if (viewType === "Single") {
      rows.push(
        buildLeftRightColumnRow(solarTuple, underline, isLoading, isMobile)
      );
    } else {
      rows.push(
        buildLeftcolMiddleRightcolRow(
          solarTuple,
          underline,
          isLoading,
          isMobile
        )
      );
    }
  }
  if (hasBattery) {
    let underline = hasInverter || hasSmartPanels;
    const batteryTuple = [
      "Batteries",
      `${formatNumber(originalProposalBatteries?.count ?? 0, 0)}`,
      `${originalProposalBatteries?.model}`,
      `${formatNumber(activeProposalBatteries?.count, 0)}`,
      `${activeProposalBatteries?.model}`,
    ];
    if (viewType === "Single") {
      rows.push(
        buildLeftRightColumnRow(batteryTuple, underline, isLoading, isMobile)
      );
    } else {
      rows.push(
        buildLeftcolMiddleRightcolRow(
          batteryTuple,
          underline,
          isLoading,
          isMobile
        )
      );
    }
  }
  if (hasInverter) {
    let underline = hasSmartPanels;

    const inverterTuple = [
      "Inverters",
      `${formatNumber(originalProposalInverters?.count ?? 0, 0)}`,
      `${originalProposalInverters?.model}`,
      `${formatNumber(activeProposalInverters?.count ?? 0, 0)}`,
      `${activeProposalInverters?.model}`,
    ];
    if (viewType === "Single") {
      rows.push(
        buildLeftRightColumnRow(inverterTuple, underline, isLoading, isMobile)
      );
    } else {
      rows.push(
        buildLeftcolMiddleRightcolRow(
          inverterTuple,
          underline,
          isLoading,
          isMobile
        )
      );
    }
  }
  if (hasSmartPanels) {
    const spanTuple = [
      "Smart Panels",
      `${formatNumber(originalProposalSpan?.count ?? 0, 0)}`,
      `${originalProposalSpan?.model}`,
      `${formatNumber(activeProposalSpan?.count, 0)}`,
      `${activeProposalSpan?.model}`,
    ];
    if (viewType === "Single") {
      rows.push(buildLeftRightColumnRow(spanTuple, false, isLoading, isMobile));
    } else {
      rows.push(
        buildLeftcolMiddleRightcolRow(spanTuple, false, isLoading, isMobile)
      );
    }
  }

  return <>{rows.map((row) => row)}</>;
};

export { ProductRows };
