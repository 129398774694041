import styled from "@emotion/styled/macro";
import { useMediaQuery } from "react-responsive";
import {
  SvgIcon,
  SvgNames,
  tokens,
  Typography,
} from "@sunrun/experience-ui-components";
import { Container } from "components/atoms/GlobalStyles";
import chevronDown from "components/assets/images/chevron-down.svg";
import { OPTY_PROGRESS_STEPS } from "checkout/util/constants";

type props = {
  showSolarStepsDetails: boolean;
  setShowSolarStepsDetails: (arg: boolean) => void;
};

type AgendaStepProps = {
  stepNumber: string;
  description: string;
  img: string;
  isMobile: boolean;
  isTablet?: boolean;
  color?: string;
  completed?: boolean;
  current?: boolean;
};

const AgendaStep = ({
  stepNumber,
  description,
  img,
  isMobile,
  isTablet,
  color,
  completed,
  current,
}: AgendaStepProps) => {
  return (
    <AgendaStepContainer isMobile={isMobile} isTablet={isTablet}>
      <img src={img} alt={description} />

      <StyledTextContainer isTablet={isTablet}>
        <StyledStepNumber color={color}>
          <Typography size={12}>{stepNumber}</Typography>
          {completed && (
            <StepMarker>
              <SvgIcon
                style={{ paddingTop: 2 }}
                width={16}
                height={16}
                color={tokens.BRAND_MEDIUM_BLUE}
                name={SvgNames.CheckEnclosed}
              />
            </StepMarker>
          )}
          {current && (
            <StepMarker>
              <SvgIcon
                width={16}
                height={16}
                color={tokens.BRAND_MEDIUM_BLUE}
                name={SvgNames.ArrowLeftFilled}
              />
            </StepMarker>
          )}
        </StyledStepNumber>
        <StyledDescription color={color}>{description}</StyledDescription>
      </StyledTextContainer>
    </AgendaStepContainer>
  );
};

const SolarSteps = ({
  showSolarStepsDetails,
  setShowSolarStepsDetails,
}: props) => {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  return (
    <SolarStepsContainer>
      <SolarStepsHeader
        showSolarStepsDetails={showSolarStepsDetails}
        onClick={() => setShowSolarStepsDetails(!showSolarStepsDetails)}
      >
        <SolarStepsTitle>
          We are here as your expert guide every step of the way.
        </SolarStepsTitle>
        <SolarStepArrow
          showSolarStepsDetails={showSolarStepsDetails}
          src={chevronDown}
          alt="Chevron arrow"
        />
      </SolarStepsHeader>
      <SolarStepsContent
        showSolarStepsDetails={showSolarStepsDetails}
        showTabletStyles={false}
        isMobile={false}
      >
        {OPTY_PROGRESS_STEPS.map((solarStep) => {
          return (
            <AgendaStep
              key={solarStep?.stepTitle}
              isMobile={isMobile}
              img={solarStep?.img}
              stepNumber={solarStep?.stepTitle}
              description={solarStep?.description}
            />
          );
        })}
      </SolarStepsContent>
    </SolarStepsContainer>
  );
};

const SolarStepsContainer = styled(Container)`
  padding-top: 60px;
`;

const SolarStepsHeader = styled.div<{
  showSolarStepsDetails: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${({ showSolarStepsDetails }) =>
    showSolarStepsDetails ? "0px" : "32px"};
`;

const SolarStepsTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
`;

const SolarStepArrow = styled.img<{
  showSolarStepsDetails: boolean;
}>`
  transition: 0.5s;
  width: 24px;
  height: 24px;
  transform: ${({ showSolarStepsDetails }) =>
    showSolarStepsDetails ? "rotate(180deg)" : ""};
`;

const SolarStepsContent = styled.div<{
  showSolarStepsDetails: boolean;
  showTabletStyles: boolean;
  isMobile: boolean;
}>`
  justify-content: space-between;
  transition: all 0.5s;
  align-items: baseline;
  padding-top: ${({ showTabletStyles }) =>
    showTabletStyles ? "12px" : "40px"};
  display: ${({ showSolarStepsDetails }) =>
    showSolarStepsDetails ? "flex" : "none"};
  flex-wrap: ${({ showTabletStyles, isMobile }) =>
    showTabletStyles && !isMobile ? "wrap" : "nowrap"};
  flex-direction: ${({ showTabletStyles }) =>
    showTabletStyles ? "column" : "unset"};
  height: ${({ isMobile, showTabletStyles }) =>
    isMobile ? "660px" : showTabletStyles ? "360px" : "unset"};
`;

const StyledTextContainer = styled.div<{
  isTablet: boolean | undefined;
}>`
  padding-left: ${({ isTablet }) => (isTablet ? "16px" : "0px")};
`;

const StyledStepNumber = styled.div<{
  color?: string;
}>`
  display: flex;
  padding-top: 12px;
  font-weight: 500;
  color: ${({ color }) => (color ? color : tokens.BRAND_HERO_BLUE)};
`;

const StepMarker = styled.span`
  margin-left: 5px;
`;

const StyledDescription = styled.div<{
  color?: string;
}>`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: ${({ color }) => (color ? color : tokens.BRAND_HERO_BLUE)};
`;

const AgendaStepContainer = styled.div<{
  isMobile: boolean;
  isTablet: boolean | undefined;
}>`
  padding-bottom: ${({ isTablet }) => (isTablet ? "24px" : "32px")};
  padding-right: ${({ isTablet }) => (isTablet ? "0px" : "16px")};
  display: flex;
  align-items: flex-start;
  flex-direction: ${({ isTablet }) => (isTablet ? "row" : "column")};
  min-width: ${({ isMobile }) => (isMobile ? "11%" : "9%")};
`;

export { SolarSteps, SolarStepsContent, AgendaStep };
