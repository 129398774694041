import styled from "@emotion/styled/macro";
import {
  Button,
  LoadingIndicator,
  tokens,
  Typography,
} from "@sunrun/experience-ui-components";
import { useParams } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  HeaderButton,
  HeaderContentLayout,
} from "../templates/HeaderContentLayout";
import { ResponsiveCardLayout } from "../templates/CardLayouts";
import { Card, CardHeaderDivider, CardSectionDivider } from "../atoms/Card";
import { ErrorComponent } from "components/atoms/ErrorComponent";
import { VivintCard } from "components/organisms/VivintCard";
import { ConfirmInfoTaskList } from "components/organisms/ConfirmInfoTaskList";
import { SiteModelModal } from "components/organisms/SiteModelModal";
import { SiteModelProgress } from "components/organisms/SiteModelProgress";
import { UTILITY_ANCHOR_KEY } from "constants/navigation";
import { useHqsEligibilityByOpportunityId } from "hooks/useHqs";
import { useAppSelector } from "store";
import { getContactsOnTitle, getPrimaryContact } from "utils/contacts";
import { useContactsData, useUsageData } from "utils/swrHooks";
import {
  useNavigateToReview,
  useNavigateToUsage,
} from "utils/useNavigateHelper";
import { useUserProfile } from "cap/hooks/useCapSwr";

const ConfirmInfoPage: React.FC = () => {
  const navigateToReview = useNavigateToReview();
  const navigateToUsage = useNavigateToUsage({ hash: UTILITY_ANCHOR_KEY });
  const { prospectId } = useParams();
  const [isSiteModelModalOpen, setOpenSiteModelModal] = useState(false);
  const authKey = useAppSelector((state) => state?.auth?.hybridToken);
  const { userProfile } = useUserProfile(authKey);

  const [error, setError] = useState<Error>();

  const {
    prospect,
    attachments,
    error: usageError,
    isLoading: isUsageLoading,
  } = useUsageData(prospectId);
  const { contacts, contactsError, isContactsLoading } = useContactsData(
    prospectId as string,
    authKey
  );
  const usage = prospect;

  const { data: isHqsEligible } = useHqsEligibilityByOpportunityId(
    prospect?.opportunityId
  );

  const handleContinue = useCallback(() => {
    navigateToReview();
  }, [navigateToReview]);

  const primaryContact = useMemo(() => getPrimaryContact(contacts), [contacts]);
  const contactsOnTitle = useMemo(
    () => getContactsOnTitle(contacts),
    [contacts]
  );

  useEffect(() => {
    if (usageError || contactsError) {
      setError(usageError ?? contactsError);
    } else {
      setError(undefined);
    }
  }, [contactsError, usageError]);

  const buttons = useMemo(() => {
    return [
      {
        type: "primary",
        text: "Continue",
        dataTestId: "confirm-info--continue",
        onClick: handleContinue,
      } as HeaderButton,
    ];
  }, [handleContinue]);

  if (error) {
    return (
      <HeaderContentLayout
        content={<ErrorComponent context="ConfirmInfoPage" error={error} />}
        title="There was a Problem."
        description={`Something went wrong. Please try refreshing the page.`}
      />
    );
  }

  return (
    <HeaderContentLayout
      title="Confirm Info & Tasks"
      description="Confirm information and complete the below tasks."
      buttons={buttons}
      content={
        <>
          <StyledResponsiveCardLayout>
            <CardsWrapper>
              <StyledCard>
                <StyledHeader>
                  <Typography variant="h4" color={tokens.BRAND_HERO_BLUE}>
                    Confirm Customer Info
                  </Typography>
                </StyledHeader>
                <CardHeaderDivider />
                <section>
                  {primaryContact ? (
                    <>
                      <StyledName data-testid="confirm-info--customer--name">{`${primaryContact.customerFirstName} ${primaryContact.customerLastName} `}</StyledName>
                      <StyledEmailPhoneContainer>
                        <span>
                          <span data-testid="confirm-info--customer--phone-number">
                            <Typography>
                              {primaryContact.customerPrimaryPhone} |&nbsp;
                            </Typography>
                          </span>
                          <span data-testid="confirm-info--customer--email">
                            <Typography>
                              {primaryContact.customerEmail}
                            </Typography>
                          </span>
                        </span>
                        <StyledButton
                          color="tertiary"
                          size="sm"
                          onClick={() => navigateToUsage()}
                        >
                          Edit
                        </StyledButton>
                      </StyledEmailPhoneContainer>
                      <CardSectionDivider />
                      <StyledNamesOnTitle>
                        <Typography>Names on Title</Typography>
                      </StyledNamesOnTitle>
                      <StyledName data-testid="confirm-info--title--name">
                        {contactsOnTitle
                          .map(
                            (contact) =>
                              `${contact.customerFirstName} ${contact.customerLastName}`
                          )
                          .join(",")}
                      </StyledName>
                      <span>
                        <Typography data-testid="confirm-info--address">
                          {prospect
                            ? `${prospect?.customerStreet}, ${prospect?.customerCity}, ${prospect?.customerState} ${prospect?.customerZipCode}`
                            : "-"}
                        </Typography>
                      </span>
                    </>
                  ) : (
                    <LoadingIndicator
                      color="black"
                      showLoadingMessage={false}
                    />
                  )}
                </section>
              </StyledCard>
              <ConfirmInfoTaskList
                contacts={contacts}
                usage={usage}
                attachments={attachments ?? []}
                isContactsLoading={isContactsLoading}
                isUsageLoading={isUsageLoading}
                isHqsEligible={isHqsEligible ?? false}
              />
              {!userProfile?.isPartner && <VivintCard />}
            </CardsWrapper>
            <div>
              <StyledSiteModelCard>
                <section>
                  <SiteModelProgress
                    prospectId={prospectId!}
                    onClick={() => setOpenSiteModelModal(true)}
                  />
                </section>
              </StyledSiteModelCard>
            </div>
            {isSiteModelModalOpen && (
              <SiteModelModal
                open
                prospectId={prospectId}
                onClose={() => setOpenSiteModelModal(false)}
              />
            )}
          </StyledResponsiveCardLayout>
        </>
      }
    />
  );
};

const StyledResponsiveCardLayout = styled(ResponsiveCardLayout)`
  @media (max-width: 1100px) {
    flex-direction: column;
  }
  margin-bottom: 40px;
`;

const StyledButton = styled(Button)`
  text-decoration: underline;
  text-underline-offset: 4px;
`;

const StyledEmailPhoneContainer = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledNamesOnTitle = styled.span`
  p {
    padding-bottom: 24px;
  }
`;

const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 26px;
  width: 100%;
`;

const StyledCard = styled(Card)`
  height: auto;
`;

const StyledSiteModelCard = styled(Card)`
  height: auto;
  padding-bottom: 16px;
`;

const StyledName = styled.div`
  font-family: Roobert;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  padding-bottom: 24px;
`;

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export { ConfirmInfoPage };
