import React, { ReactNode, useEffect } from "react";
import { EmblaOptionsType } from "embla-carousel";
import styled from "@emotion/styled/macro";
import useEmblaCarousel from "embla-carousel-react";
import {
  DotButton,
  EmblaDots,
  NextButton,
  PrevButton,
  useDotButton,
  usePrevNextButtons,
} from "../atoms/emblaAtoms";

type PropType = {
  slides: ReactNode[];
  options?: EmblaOptionsType;
  showDots?: boolean;
  requestedIndex: number;
};

const FullSizeCarousel: React.FC<PropType> = (props) => {
  const { slides, requestedIndex, options } = props;
  const [ref, api] = useEmblaCarousel(options);

  useEffect(() => {
    if (requestedIndex > -1) {
      api?.scrollTo(requestedIndex);
    }
  }, [api, requestedIndex]);

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(api);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(api);

  return (
    <CustomEmbla>
      <RelativeContainer>
        {slides.length > 1 && (
          <EmblaButtonContainer>
            <PrevButton
              onClick={onPrevButtonClick}
              disabled={prevBtnDisabled}
            />
            <NextButton
              onClick={onNextButtonClick}
              disabled={nextBtnDisabled}
            />
          </EmblaButtonContainer>
        )}
        <CustomViewport ref={ref}>
          <CustomContainer>
            {slides.map((child, index) => (
              <CustSlideContainer key={index}>
                <CustSlide onClick={() => onDotButtonClick(index)}>
                  {child}
                </CustSlide>
              </CustSlideContainer>
            ))}
          </CustomContainer>
        </CustomViewport>
      </RelativeContainer>
      <EmblaDots>
        {scrollSnaps.map((_, index) => (
          <DotButton
            key={index}
            onClick={() => onDotButtonClick(index)}
            className={"embla__dot".concat(
              index === selectedIndex
                ? " embla__dot--selected full-size"
                : " full-size"
            )}
          />
        ))}
      </EmblaDots>
    </CustomEmbla>
  );
};

const EmblaButtonContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1100px;
  justify-content: space-between;
  position: absolute;
  top: calc(50% - 15px);
  left: 50%;
  transform: translate(-50%);
  z-index: 1;
`;

const RelativeContainer = styled.div`
  position: relative;
`;

const CustomEmbla = styled.section`
  height: calc(100vh - 150px);
  width: 100vw;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const CustomContainer = styled.div`
  touch-action: pan-y pinch-zoom;
  display: flex;
`;

const CustomViewport = styled.div`
  overflow: hidden;
  min-height: 200px;
`;

const CustSlide = styled.div`
  flex: 0 0 var(--slide-size);
  min-width: 0;
  width: 90vw;
  max-width: 1000px;
`;

const CustSlideContainer = styled.div`
  width: 100vw;
  padding: 0 5vw;
`;

export { FullSizeCarousel };
