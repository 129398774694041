import React, { useState } from "react";
import {
  Button,
  Skeleton,
  SvgIcon,
  SvgNames,
  tokens,
  Typography,
} from "@sunrun/experience-ui-components";
import styled from "@emotion/styled/macro";
import { isArray } from "lodash";
import { useMediaQuery } from "react-responsive";
import { CapSteps } from "../../constants/capConstants";
import { useCapContext } from "../../providers/CapContext";
import { StepName } from "../../capTypes";
import { HamburgerMenu } from "./HamburgerMenu";

type CapSidebarProps = {
  currentStep: StepName;
  changeStep: (step: StepName) => void;
  isLoading: boolean;
};

const CapNav = ({ currentStep, changeStep, isLoading }: CapSidebarProps) => {
  const isMobile = useMediaQuery({ maxWidth: 750 });
  const goToStep = (step: StepName) => {
    changeStep(step);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const {
    cap,
    sow,
    prospect,
    sfdcUrl,
    oneBaseUrl,
    splatBaseUrl,
    reviewOfferId,
    capTask,
    equipmentLocationImages,
    isCustomer,
  } = useCapContext();

  const projectNotesUrl = `${sfdcUrl}/apex/wfproject_lwc?id=${sow?.projectId}&sfdc.override=1&c__tabName=notes`;
  const customerProfileUrl = `${oneBaseUrl}/customers/customer-profile/${prospect?.prospectId}`;
  const revisitOfferPresentation = `${splatBaseUrl}/go-solar/${prospect?.prospectId}/offer/${reviewOfferId}`;
  const capInSfdcUrl = `${sfdcUrl}/apex/wftask_lwc?id=${capTask?.id}&sfdc.override=1&c__tabName=DETAILS`;

  const fries = [
    [capInSfdcUrl, "CAP in Salesforce"],
    [capInSfdcUrl, "CAP changes needed"],
    [projectNotesUrl, "Project notes"],
    [customerProfileUrl, "Customer profile"],
    [revisitOfferPresentation, "Revisit offer presentation"],
  ];

  const filteredCapSteps = CapSteps.filter((step) => {
    return !(
      (cap?.type === "A" || cap?.type === "B") &&
      step.name === "Home readiness"
    );
  }).filter((step) => {
    return !(
      step.name === "Equipment location" &&
      !!equipmentLocationImages &&
      isArray(equipmentLocationImages) &&
      equipmentLocationImages.length < 1
    );
  });

  const getSteps = () => {
    let steps = filteredCapSteps.map(({ order }) => (
      <LoadingSkeletonSteps key={order} />
    ));

    if (!isLoading) {
      steps = filteredCapSteps.map((capStep) => (
        <CapNavItem
          key={capStep.order}
          onClick={() => goToStep(capStep.name)}
          style={{
            borderRadius: "unset",
            borderBottom:
              capStep.name === currentStep
                ? `3px solid ${tokens.BRAND_HERO_BLUE}`
                : "none",
          }}
        >
          <Typography
            size={17}
            color={
              capStep.name === currentStep
                ? `${tokens.BRAND_HERO_BLUE}`
                : `${tokens.TINTS_OFF_WHITE_20}`
            }
            lineHeight={20}
          >
            {capStep.name}
          </Typography>
        </CapNavItem>
      ));
    }
    return steps;
  };

  const [showScrollLeftOption, setShowScrollLeftOption] = useState(false);
  const shouldHaveScrollOptions = isMobile && filteredCapSteps.length > 3;

  const scrollNav = (direction: "left" | "right") => {
    document.getElementById("steps-list")?.scrollBy({
      left: direction === "right" ? 300 : -300,
      behavior: "smooth",
    });

    setShowScrollLeftOption(direction === "right" ? true : false);
  };

  return (
    <TabNav>
      {shouldHaveScrollOptions && showScrollLeftOption && (
        <ScrollButton onClick={() => scrollNav("left")}>
          <SvgIcon
            name={SvgNames.ChevronLeft}
            width={18}
            height={18}
            color={tokens.HEROBLUE_70}
          />
        </ScrollButton>
      )}
      <StepsList
        id="steps-list"
        isMobile={isMobile}
        showRightScrollStyles={shouldHaveScrollOptions}
      >
        {getSteps()}
      </StepsList>
      {shouldHaveScrollOptions && !isLoading && (
        <ScrollButton onClick={() => scrollNav("right")}>
          <SvgIcon
            name={SvgNames.ChevronRight}
            width={18}
            height={18}
            color={tokens.HEROBLUE_70}
          />
        </ScrollButton>
      )}
      {isCustomer ? (
        <></>
      ) : isLoading ? (
        <CircleSkeleton />
      ) : (
        <HamburgerWrapper isMobile={isMobile}>
          <HamburgerMenu fries={fries} isMobile={isMobile} />
        </HamburgerWrapper>
      )}
    </TabNav>
  );
};

const HamburgerWrapper = styled.div<{ isMobile: boolean }>`
  position: ${({ isMobile }) => (isMobile ? "absolute" : "static")};
  right: 12px;
  top: 130px;
  margin-left: auto;
  padding-right: 24px;
  width: 32px;
  height: 32px;
`;

const LoadingSkeletonSteps = styled(Skeleton)`
  width: 76px;
  height: 17px;
`;

const CircleSkeleton = styled(Skeleton)`
  height: 32px;
  width: 32px;
  border-radius: 16px;
`;

const CapNavItem = styled(Button)`
  display: flex;
  min-width: fit-content;
  background: none;
  padding: 8px;
`;

const StepsList = styled.div<{
  isMobile: boolean;
  showRightScrollStyles: boolean;
}>`
  display: flex;
  gap: ${({ isMobile }) => (isMobile ? "12px" : "24px")};
  width: ${({ isMobile }) => (isMobile ? "auto" : "90%")};
  overflow: scroll;

  ::-webkit-scrollbar {
    height: 0px;
    width: 0px;
  }
`;

const ScrollButton = styled.button`
  all: unset;
  display: flex;
  align-items: center;
`;

const TabNav = styled.nav`
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 1700px) {
    min-width: 1220px;
  }
`;

export { CapNav };
