import React, { ReactElement, useCallback, useEffect, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import styled from "@emotion/styled/macro";
import { EmblaOptionsType } from "embla-carousel";
import {
  Button,
  SvgIcon,
  SvgNames,
  tokens,
} from "@sunrun/experience-ui-components";
import { RelativeContainer, RowRightContent } from "../atoms/layoutAtoms";
import {
  DotButton,
  EmblaDots,
  NextButton,
  PrevButton,
  useDotButton,
  usePrevNextButtons,
} from "../atoms/emblaAtoms";
import {
  CarouselModal,
  CloseCarouselButton,
} from "../organisms/HomeReadinessStepView";
import { FullSizeCarousel } from "./FullSizeCarousel";

type CarouselWithThumbsProps = {
  slides: ReactElement[];
  options?: EmblaOptionsType;
};

type ThumbProps = {
  selected: boolean;
  slide: ReactElement;
  onClick: () => void;
};

const Thumb: React.FC<ThumbProps> = ({ selected, slide, onClick }) => (
  <ThumbSlide
    className={"embla-thumbs__slide".concat(
      selected ? " embla-thumbs__slide--selected" : ""
    )}
  >
    <EmblaThumbsSlideContent
      onClick={onClick}
      type="button"
      className="embla-thumbs__slide__number"
    >
      {slide}
    </EmblaThumbsSlideContent>
  </ThumbSlide>
);

const CarouselWithThumbs: React.FC<CarouselWithThumbsProps> = ({ slides }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [emblaMainRef, emblaMainApi] = useEmblaCarousel({ loop: true });
  const [emblaThumbsRef, emblaThumbsApi] = useEmblaCarousel({
    containScroll: "keepSnaps",
    dragFree: true,
  });

  const onThumbClick = useCallback(
    (index: number) => {
      if (!emblaMainApi || !emblaThumbsApi) return;
      emblaMainApi.scrollTo(index);
    },
    [emblaMainApi, emblaThumbsApi]
  );

  const onSelect = useCallback(() => {
    if (!emblaMainApi || !emblaThumbsApi) return;
    setSelectedIndex(emblaMainApi.selectedScrollSnap());
    emblaThumbsApi.scrollTo(emblaMainApi.selectedScrollSnap());
  }, [emblaMainApi, emblaThumbsApi, setSelectedIndex]);

  useEffect(() => {
    if (!emblaMainApi) return;
    onSelect();

    emblaMainApi.on("select", onSelect).on("reInit", onSelect);
  }, [emblaMainApi, onSelect]);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaMainApi);

  const { scrollSnaps, onDotButtonClick } = useDotButton(emblaMainApi);

  const [fullImageIndex, setFullImageIndex] = useState(-1);

  const openLargeImageCarousel = useCallback((index: number) => {
    setFullImageIndex(index);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <Carousel>
      <RelativeContainer>
        <EmblaButtonContainer>
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </EmblaButtonContainer>
        <Viewport ref={emblaMainRef}>
          <Container>
            {slides.map((content, index) => (
              <InnerSlide key={index}>
                {fullImageIndex === -1 && (
                  <RelativeContainer>
                    <BrandBlueButton
                      onClick={() => openLargeImageCarousel(index)}
                    >
                      <SvgIcon
                        width={24}
                        height={24}
                        name={SvgNames.ArrowsOutSimple}
                        color={tokens.TINTS_OFF_WHITE_90}
                      />
                    </BrandBlueButton>
                  </RelativeContainer>
                )}
                <SlideContent>{content}</SlideContent>
              </InnerSlide>
            ))}
          </Container>
          <EmblaDotsContainer>
            <EmblaDots>
              {scrollSnaps.map((_, index) => (
                <DotButton
                  key={index}
                  onClick={() => onDotButtonClick(index)}
                  className={"embla__dot".concat(
                    index === selectedIndex ? " embla__dot--selected" : ""
                  )}
                />
              ))}
            </EmblaDots>
          </EmblaDotsContainer>
        </Viewport>
      </RelativeContainer>
      <Thumbs>
        <ThumbsViewport ref={emblaThumbsRef}>
          <ThumbsContainer>
            {slides.map((slide, index) => (
              <CustThumbContainer key={index}>
                <Thumb
                  onClick={() => onThumbClick(index)}
                  selected={index === selectedIndex}
                  slide={slide}
                />
              </CustThumbContainer>
            ))}
          </ThumbsContainer>
        </ThumbsViewport>
      </Thumbs>
      {fullImageIndex > -1 && (
        <CarouselModal
          customModalWidth={visualViewport?.width ?? 100}
          hideClose={true}
        >
          <RowRightContent>
            <CloseCarouselButton onClick={() => setFullImageIndex(-1)}>
              <SvgIcon
                height={20}
                width={20}
                color={tokens.TINTS_OFF_WHITE_80}
                name={SvgNames.Close}
              />
            </CloseCarouselButton>
          </RowRightContent>
          <FullSizeCarousel
            requestedIndex={fullImageIndex}
            slides={slides}
            options={{ loop: true }}
            showDots={true}
          />
        </CarouselModal>
      )}
    </Carousel>
  );
};

const CustThumbContainer = styled.div`
  padding-right: 8px;
  height: var(--thumbs-slide-height);
  flex-shrink: 0;
  width: 144px;
`;

const EmblaThumbsSlideContent = styled.button`
  border-radius: 1.8rem;
  background-color: transparent;
  touch-action: manipulation;
  cursor: pointer;
  border: 0;
  padding: 0;
  height: var(--thumbs-slide-height);
  width: 100%;
`;

const Carousel = styled.div`
  --slide-size: 100%;
  gap: 10px;
  display: flex;
  flex-direction: column;
`;

const Viewport = styled.div`
  overflow: hidden;
`;

const Container = styled.div`
  display: flex;
  touch-action: pan-y pinch-zoom;
`;

const InnerSlide = styled.div`
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size);
  min-width: 0;
`;

const SlideContent = styled.div`
  border-radius: 1.8rem;
  height: var(--slide-height);
  user-select: none;
`;

const Thumbs = styled.div`
  --thumbs-slide-height: 6rem;
  position: relative;
`;

const ThumbsViewport = styled.div`
  overflow: hidden;
`;

const ThumbsContainer = styled.div`
  display: flex;
`;

const ThumbSlide = styled.div`
  flex: 0 0 23%;
  min-width: 0;
  border-radius: 4px;
`;

const BrandBlueButton = styled(Button)`
  background: ${tokens.BRAND_HERO_BLUE};
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 2px;
  z-index: 1001;
  position: absolute;
  right: 16px;
  top: 16px;
`;

const EmblaButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: absolute;
  top: calc(50% - 15px);
`;

const EmblaDotsContainer = styled.div`
  position: absolute;
  bottom: 10px;
  width: 100%;
`;

export { CarouselWithThumbs };
