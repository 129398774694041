import {
  SvgIcon,
  SvgNames,
  tokens,
  Typography,
  Skeleton,
} from "@sunrun/experience-ui-components";
import React, { ReactNode, useEffect, useState } from "react";
import styled from "@emotion/styled/macro";
import { useMediaQuery } from "react-responsive";
import { useCapContext } from "../../providers/CapContext";
import { Column, Row, RowRightContent } from "../atoms/layoutAtoms";
import { FinancialTermsRows } from "../molecules/FinancialTermRows";
import { SystemRows } from "../molecules/SystemRows";
import { ProductRows } from "../molecules/ProductRows";
import { ViewType } from "../../capTypes";
import { HamburgerMenu } from "../molecules/HamburgerMenu";
import { FullSizeCarousel } from "../molecules/FullSizeCarousel";
import {
  BrandBlueButton,
  CarouselModal,
  CloseCarouselButton,
  ExpandImageButtonRow,
} from "./HomeReadinessStepView";

const DesignStepView: React.FC = () => {
  const {
    activeProposal,
    activeProposalDesignImages,
    activeProposalLoading,
    cap,
    capTask,
    contacts,
    isCapLoading,
    isContactsLoading,
    isCustomer,
    isSowLoading,
    originalProposalDesignImages,
    originalProposalDesignImagesLoading,
    prospectLoading,
    sfdcUrl,
  } = useCapContext();

  const [viewType, setViewType] = useState<ViewType>("Single");
  const [showFullSizeImage, setShowFullSizeImage] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 750 });
  const isTablet = useMediaQuery({ maxWidth: 1024 });

  let smallOriginalImage, largerOriginalImage: { url: string };
  if (originalProposalDesignImages) {
    smallOriginalImage = originalProposalDesignImages.find(
      (image: { documentName: string | string[] }) => {
        return image.documentName.includes("design-image-small.jpg");
      }
    );

    largerOriginalImage = originalProposalDesignImages.find(
      (image: { documentName: string | string[] }) => {
        return image.documentName.includes("design-image-large.jpg");
      }
    );
  }

  let smallProposalImage, largerNewImage: { url: string };
  if (activeProposalDesignImages) {
    smallProposalImage = activeProposalDesignImages.find(
      (image: { documentName: string | string[] }) => {
        return image.documentName.includes("design-image-small.jpg");
      }
    );

    largerNewImage = activeProposalDesignImages.find(
      (image: { documentName: string | string[] }) => {
        return image.documentName.includes("design-image-large.jpg");
      }
    );
  }

  useEffect(() => {
    if (
      (cap?.type === "B" || cap?.type === "D") &&
      activeProposal?.parentProposalId
    ) {
      setViewType("Comparison");
    }
  }, [activeProposal?.parentProposalId, cap?.type]);

  const capInSfdcUrl = `${sfdcUrl}/apex/wftask_lwc?id=${capTask?.id}&sfdc.override=1&c__tabName=DETAILS`;
  const changeRequestGoogleFormUrl = `https://docs.google.com/forms/d/e/1FAIpQLSdWCQVz9M_suzlN3Zohfw-BgsUENb3enUr8isvwMS0b21lrjA/viewform`;
  const specificationLinks = [[capInSfdcUrl, "Request design change"]];
  const termsLinks = [[changeRequestGoogleFormUrl, "Request contract change"]];

  const isLoading =
    !contacts ||
    isContactsLoading ||
    activeProposalLoading ||
    isSowLoading ||
    prospectLoading ||
    originalProposalDesignImagesLoading ||
    isCapLoading;

  const [slides, setSlides] = useState<ReactNode[]>([]);

  const openLargeImageCarousel = (imageType: string) => {
    if (imageType === "original") {
      setSlides([<LargeOriginalImage />]);
    } else {
      setSlides([<LargeNewImage />]);
    }
    setShowFullSizeImage(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const LargeOriginalImage = () => {
    return (
      <StyledLargeImage
        largeImageUrl={largerOriginalImage?.url}
      ></StyledLargeImage>
    );
  };

  const LargeNewImage = () => {
    return (
      <StyledLargeImage largeImageUrl={largerNewImage?.url}></StyledLargeImage>
    );
  };

  const DesignRowHeader = ({
    title,
    links,
  }: {
    title: string;
    links: string[][];
  }) => {
    return (
      <HeaderRow>
        {isLoading ? (
          <HeaderContainerSkeleton />
        ) : (
          <>
            <Spacer />
            <HeaderContainer isMobile={isMobile}>{title}</HeaderContainer>
            <HamburgerWrapper>
              {!isCustomer && <HamburgerMenu fries={links} />}
            </HamburgerWrapper>
          </>
        )}
      </HeaderRow>
    );
  };

  return (
    <>
      <Typography
        color={tokens.BRAND_HERO_BLUE}
        lineHeight={isTablet ? 32 : 38}
        size={isTablet ? 24 : 32}
        style={{
          marginTop: isMobile ? 16 : 24,
          marginBottom: isMobile ? 16 : 24,
        }}
      >
        {isLoading ? <Skeleton /> : "Review your finalized design"}
      </Typography>

      {/*Single view*/}
      {viewType === "Single" && (
        <DesignCard isMobile={isMobile}>
          {isLoading ? (
            <DesignImageSkeleton />
          ) : (
            <DesignImage
              bgImageUrl={smallProposalImage?.url}
              isMobile={isMobile}
            >
              {!showFullSizeImage && (
                <ExpandImageButtonRow>
                  <RowRightContent>
                    <BrandBlueButton
                      style={{ zIndex: 1001, marginRight: 24 }}
                      onClick={(e) => openLargeImageCarousel("new")}
                    >
                      <SvgIcon
                        width={24}
                        height={24}
                        name={SvgNames.ArrowsOutSimple}
                        color={tokens.TINTS_OFF_WHITE_90}
                      />
                    </BrandBlueButton>
                  </RowRightContent>
                </ExpandImageButtonRow>
              )}
            </DesignImage>
          )}
          <DesignRowHeader title="Specifications" links={specificationLinks} />
          <SystemRows viewType={viewType} isLoading={isLoading} />
          <ProductRows viewType={viewType} isLoading={isLoading} />
          <DesignRowHeader title="Terms" links={termsLinks} />
          <FinancialTermsRows viewType={viewType} isLoading={isLoading} />
        </DesignCard>
      )}
      {showFullSizeImage && (
        <CarouselModal
          customModalWidth={visualViewport?.width ?? 100}
          hideClose={true}
        >
          <RowRightContent>
            <CloseCarouselButton onClick={() => setShowFullSizeImage(false)}>
              <SvgIcon
                height={20}
                width={20}
                color={tokens.TINTS_OFF_WHITE_80}
                name={SvgNames.Close}
              />
            </CloseCarouselButton>
          </RowRightContent>
          <FullSizeCarousel
            requestedIndex={0}
            slides={slides}
            options={{ inViewThreshold: 1 }}
            showDots={true}
          />
        </CarouselModal>
      )}
      {/*Comparison view*/}
      {viewType === "Comparison" && (
        <DesignCard isMobile={isMobile}>
          <DesignImageRow isMobile={isMobile}>
            <DesignImageColumn>
              {isLoading ? (
                <DesignImageSkeleton />
              ) : (
                <>
                  <Typography size={18} fontWeight={"medium"}>
                    Original
                  </Typography>
                  <ComparisonDesignImage
                    bgImageUrl={smallOriginalImage?.url}
                    isMobile={isMobile}
                    isTablet={isTablet}
                  >
                    {!showFullSizeImage && (
                      <ExpandImageButtonRow>
                        <RowRightContent>
                          <BrandBlueButton
                            style={{ zIndex: 1001, marginRight: 24 }}
                            onClick={(e) => openLargeImageCarousel("original")}
                          >
                            <SvgIcon
                              width={24}
                              height={24}
                              name={SvgNames.ArrowsOutSimple}
                              color={tokens.TINTS_OFF_WHITE_90}
                            />
                          </BrandBlueButton>
                        </RowRightContent>
                      </ExpandImageButtonRow>
                    )}
                  </ComparisonDesignImage>
                </>
              )}
            </DesignImageColumn>
            <DesignImageColumn>
              {isLoading ? (
                <DesignImageSkeleton />
              ) : (
                <>
                  <Typography size={18} fontWeight={"medium"}>
                    Final Design
                  </Typography>
                  <ComparisonDesignImage
                    bgImageUrl={smallProposalImage?.url}
                    isMobile={isMobile}
                    isTablet={isTablet}
                  >
                    {!showFullSizeImage && (
                      <ExpandImageButtonRow>
                        <RowRightContent>
                          <BrandBlueButton
                            style={{ zIndex: 1001, marginRight: 24 }}
                            onClick={(e) => openLargeImageCarousel("new")}
                          >
                            <SvgIcon
                              width={24}
                              height={24}
                              name={SvgNames.ArrowsOutSimple}
                              color={tokens.TINTS_OFF_WHITE_90}
                            />
                          </BrandBlueButton>
                        </RowRightContent>
                      </ExpandImageButtonRow>
                    )}
                  </ComparisonDesignImage>
                </>
              )}
            </DesignImageColumn>
          </DesignImageRow>
          <DesignRowHeader title="Specifications" links={specificationLinks} />
          <SystemRows viewType={viewType} isLoading={isLoading} />
          <ProductRows viewType={viewType} isLoading={isLoading} />
          <DesignRowHeader title="Terms" links={termsLinks} />
          <FinancialTermsRows viewType={viewType} isLoading={isLoading} />
        </DesignCard>
      )}
    </>
  );
};

const DesignImageRow = styled(Row)`
  flex-direction: row;
  gap: ${({ isMobile }) => (isMobile ? "12px" : "60px")};
  padding: 0;
`;

const DesignImageColumn = styled(Column)`
  flex-grow: 1;
  align-items: center;
  width: 50%;

  & > p {
    padding: 8px;
    margin-bottom: 16px;
  }
`;

const HamburgerWrapper = styled.div`
  padding-right: 24px;
  width: 32px;
  height: 32px;
`;

const Spacer = styled.div`
  width: 56px;
  /* the same width as the hamburger icon wrapper, 32px, plus it's padding, 24px */
`;

const DesignCard = styled.section<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background: ${tokens.BACKGROUND_LIGHT};
  border-radius: 8px;
  padding: ${({ isMobile }) => (isMobile ? "16px 16px 0px" : "32px 64px 0px")};
`;

const DesignImage = styled.div<{
  bgImageUrl: string;
  isMobile: boolean;
}>`
  height: ${({ isMobile }) => (isMobile ? "inherit" : "433px")};
  padding: 16px;
  aspect-ratio: 4/3;
  ${({ bgImageUrl }) =>
    bgImageUrl ? `background: url(${bgImageUrl}) no-repeat center;` : ""}
  background-size: cover;
  border-radius: 8px;
  margin-bottom: 32px;
`;

const DesignImageSkeleton = styled(Skeleton)`
  height: 443px;
  width: 100%;
  margin-bottom: 32px;
`;

const ComparisonDesignImage = styled.div<{
  bgImageUrl: string;
  isMobile: boolean;
  isTablet: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: ${({ isMobile, isTablet }) =>
    isMobile ? "168px" : isTablet ? "200px" : "360px"};
  ${({ bgImageUrl }) =>
    bgImageUrl ? `background: url(${bgImageUrl}) no-repeat center;` : ""}
  background-size: cover;
  border-radius: 8px;
  margin-bottom: ${({ isMobile }) => (isMobile ? "16px" : "22px")};
`;

const StyledLargeImage = styled.div<{
  largeImageUrl: string;
}>`
  display: flex;
  width: 100%;
  min-height: 100px;
  aspect-ratio: 3/2;
  ${({ largeImageUrl }) =>
    largeImageUrl ? `background: url(${largeImageUrl}) no-repeat center;` : ""}
  background-size: cover;
  border-radius: 8px;
  margin-bottom: 32px;
`;

const HeaderRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background: #f4f1ec;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 1px rgba(208, 204, 195, 0.6) solid;
  padding: 0;
`;

const HeaderContainer = styled.div<{ isMobile: boolean }>`
  color: #1f2647;
  line-height: ${({ isMobile }) => (isMobile ? "19px" : "22px")};
  font-size: ${({ isMobile }) => (isMobile ? "16px" : "19px")};
  font-family: "Roobert", sans-serif;
  font-weight: 500;
  word-wrap: break-word;
  padding: 24px;
`;

const HeaderContainerSkeleton = styled(Skeleton)`
  padding: 24px;
  flex: 1 1 0;
  text-align: center;
  color: #1f2647;
  font-size: 19px;
  font-family: "Roobert", sans-serif;
  font-weight: 500;
  word-wrap: break-word;
`;

export { DesignStepView };
