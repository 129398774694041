import styled from "@emotion/styled/macro";
import {
  Card,
  Pill,
  tokens,
  Tooltip,
  Typography,
} from "@sunrun/experience-ui-components";
import { useParams } from "react-router-dom";
import { useFlags } from "flagsmith/react";
import { useEffect, useState } from "react";
import { VivintEligibilityResponse } from "cap/capTypes";
import { FeatureFlags } from "fixtures/features";
import { useAuthToken } from "hooks/useAuthToken";
import { rollbar } from "providers/rollbar";
import { checkVSHEligibility } from "services/vivintEligibility";

const VivintCard = () => {
  const { prospectId } = useParams();
  const authToken = useAuthToken("IHD");
  const [vivintData, setVivintData] = useState<
    VivintEligibilityResponse | undefined
  >();
  const flags = useFlags([FeatureFlags.VIVINT_SMART_HOME_UI]);
  const canViewVivintSmartHome =
    flags[FeatureFlags.VIVINT_SMART_HOME_UI]?.enabled;

  useEffect(() => {
    if (!authToken?.token || !prospectId || !canViewVivintSmartHome) {
      return;
    }
    checkVSHEligibility(authToken.token, prospectId)
      .then((vshData) => {
        setVivintData(vshData);
      })
      .catch((error) => {
        rollbar.error(error);
      });
  }, [authToken, prospectId, canViewVivintSmartHome]);

  if (!vivintData) return null;

  return (
    <StyledCard dataTestId="vivint-card">
      <StyledHeader>
        <Typography variant="h4" color={tokens.BRAND_HERO_BLUE}>
          Vivint Smart Home
        </Typography>
        {vivintData?.eligibility?.isEligible ? (
          <Pill text="Eligible" />
        ) : (
          <StyledTooltip
            className="icon-tooltip"
            content={
              vivintData?.eligibility?.zipcodeIsEligble ? (
                <TooltipText>
                  Vivint Smart Home not elegible
                  <br /> due to previous relationship
                </TooltipText>
              ) : (
                <TooltipText>
                  Vivint Smart Home not elegible
                  <br /> due to zip code
                </TooltipText>
              )
            }
          >
            <Pill text="Not Eligible" type="info" />
          </StyledTooltip>
        )}
      </StyledHeader>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  height: auto;
`;
const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTooltip = styled(Tooltip)`
  && .sr-tooltip-content {
    min-width: max-content;
    right: calc(50% - 140px);
  }
`;

const TooltipText = styled.span`
  margin: 0;
`;

export { VivintCard };
