const FeatureFlags = {
  OFFER_EV_SPAN: "sales-exp-offer-ev-span",
  IL_IPA_EMAIL_ENABLED: "send-il-ipa-email",
  USAGE_CALCULATOR_IN_OFFER_EXPERIENCE: "usage-calculator-in-offer-experience",
  VIVINT_SMART_HOME_UI: "vivint_smart_home_ui_one",
  CONFIRM_INFO_SHOW_DESIGN_REQUEST:
    "sales-exp-confirm-info-show-design-request",
};

export { FeatureFlags };
