import {
  Button,
  SvgIcon,
  SvgNames,
  tokens,
  Typography,
} from "@sunrun/experience-ui-components";
import React, { useCallback, useState } from "react";
import styled from "@emotion/styled/macro";

type HamburgerMenuProps = {
  fries: string[][];
  isMobile?: boolean;
};

const HamburgerMenu = ({ fries, isMobile }: HamburgerMenuProps) => {
  const [showFries, setShowFries] = useState(false);
  const goToLinkCloseMenu = useCallback((url: string) => {
    window.open(url, "_blank");
    setShowFries(false);
  }, []);
  return (
    <>
      <Hamburger
        onClick={() => setShowFries(!showFries)}
        isMobile={isMobile || false}
      >
        <SvgIcon
          width={24}
          height={24}
          color={isMobile ? tokens.WHITE : tokens.BRAND_MEDIUM_BLUE}
          name={SvgNames.VerticalDotsSvg}
        />
      </Hamburger>
      {showFries && (
        <Fries>
          {fries.map((fry, index) => (
            <Fry
              index={index}
              key={index}
              role={"button"}
              onClick={() => goToLinkCloseMenu(fry[0])}
            >
              <FryContent>
                <Typography
                  fontWeight={"medium"}
                  size={16}
                  lineHeight={24}
                  color={tokens.TINTS_HERO_BLUE_50}
                >
                  {fry[1]}
                </Typography>
                <SvgIcon
                  width={16}
                  height={16}
                  color={tokens.TINTS_HERO_BLUE_50}
                  name={SvgNames.ExternalLink}
                />
              </FryContent>
            </Fry>
          ))}
        </Fries>
      )}
    </>
  );
};

const Hamburger = styled(Button)<{ isMobile: boolean }>`
  background: unset;
  display: flex;
  border: 1px solid
    ${({ isMobile }) => (isMobile ? tokens.WHITE : tokens.BRAND_MEDIUM_BLUE)};
  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding: 0;

  & > span {
    display: flex;
  }
`;

const fryWidth = "208px";

const Fries = styled.ul`
  /* negative left margin of the fry width to offset ul */
  margin-left: -${fryWidth};
  display: inline-block;
  list-style: none;
  background-color: ${tokens.TINTS_OFF_WHITE_90};
  position: sticky;
  right: 64px;
  margin-top: 16px;
  padding-inline-start: 0;
  z-index: 1005;
  border-radius: 10px;
  background: var(--white, #fff);
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.1);

  li:last-child {
    border-bottom: none;
  }
`;

const Fry = styled.li<{ index: number }>`
  position: relative;
  display: block;
  min-width: ${fryWidth};
  padding: 12px 16px;
  align-items: center;
  gap: 8px;

  height: 22px;
  ${({ index }) =>
    index !== 0 ? `border-top: 1px solid ${tokens.OFFWHITE_30};` : ""}
  & > svg {
    margin-left: 10px;
  }

  &:hover {
    p,
    svg {
      opacity: 70%;
    }
  }
`;

const FryContent = styled.div`
  display: block;
  display: flex;
  gap: 8px;
  align-items: center;
`;

export { HamburgerMenu };
