import {
  Button,
  Modal,
  SvgIcon,
  SvgNames,
  tokens,
  Typography,
} from "@sunrun/experience-ui-components";
import React, { useCallback, useState } from "react";
import styled from "@emotion/styled/macro";
import { useMediaQuery } from "react-responsive";
import { formatCurrency } from "../../../utils/formatHelpers";
import { useCapContext } from "../../providers/CapContext";
import {
  ContentLeft,
  ContentRight,
  RelativeContainer,
  Row,
  RowRightContent,
} from "../atoms/layoutAtoms";
import { SimpleCarousel } from "../molecules/SimpleCarousel";
import { FullSizeCarousel } from "../molecules/FullSizeCarousel";
import { SowLineItemDescriptions } from "../../constants/sowLineItemDescription";
import { LineItem, WorkItem, WorkType } from "../../capTypes";

interface WorkItemsProps {
  workItem: WorkItem;
  isMobile: boolean;
}

interface LineItemProps {
  lineItem: LineItem;
  isMobile: boolean;
}

const SUNRUN_MANAGED = "Sunrun managed";
const CUSTOMER_MANAGED = "Customer managed";

const WorkLineItem = ({
  lineItem,
  isMobile,
}: LineItemProps): React.JSX.Element => {
  const isCustomerManaged = lineItem?.line_item_assignment
    ?.toLowerCase()
    .includes("customer managed");

  const [showLineItemDetailModal, setShowLineItemDetailModal] = useState(false);

  const lineItemPrettyDescription = SowLineItemDescriptions.map((d) => {
    return d[lineItem.line_item_subType]?.description ?? "";
  });

  return (
    <SowLineItem>
      <SingleSpacedRow>
        <LineItemLeft isMobile={isMobile}>
          <Typography size={20} fontWeight={"medium"}>
            {lineItem.line_item_subType}
          </Typography>
          <ClearButton onClick={() => setShowLineItemDetailModal(true)}>
            <SvgIcon
              name={SvgNames.InfoOutline}
              width={18}
              height={18}
              color={tokens.BRAND_HERO_BLUE}
            />
          </ClearButton>
        </LineItemLeft>
        <LineItemRight isMobile={isMobile}>
          <StyledContentLeft isMobile={isMobile}>
            {!isMobile && (
              <>
                {isCustomerManaged ? (
                  <CustomerChip isMobile={isMobile}>
                    <Typography
                      color={tokens.TINTS_OFF_WHITE_90}
                      size={16}
                      fontWeight={"medium"}
                      lineHeight={19}
                    >
                      {CUSTOMER_MANAGED}
                    </Typography>
                  </CustomerChip>
                ) : (
                  <SunrunChip isMobile={isMobile}>
                    <Typography
                      color={tokens.BRAND_HERO_BLUE}
                      size={16}
                      fontWeight={"medium"}
                      lineHeight={19}
                    >
                      {SUNRUN_MANAGED}
                    </Typography>
                  </SunrunChip>
                )}
              </>
            )}
          </StyledContentLeft>
          <StyledContentRight isMobile={isMobile}>
            <Typography color={tokens.TINTS_OFF_WHITE_20} size={24}>
              {formatCurrency(lineItem.line_item_price)}
            </Typography>
          </StyledContentRight>
        </LineItemRight>
      </SingleSpacedRow>
      {isMobile && (
        <>
          {isCustomerManaged ? (
            <CustomerChip isMobile={isMobile}>
              <Typography
                color={tokens.TINTS_OFF_WHITE_90}
                size={16}
                fontWeight={"medium"}
                lineHeight={19}
              >
                {CUSTOMER_MANAGED}
              </Typography>
            </CustomerChip>
          ) : (
            <SunrunChip isMobile={isMobile}>
              <Typography
                color={tokens.BRAND_HERO_BLUE}
                size={16}
                fontWeight={"medium"}
                lineHeight={19}
              >
                {SUNRUN_MANAGED}
              </Typography>
            </SunrunChip>
          )}
        </>
      )}
      <SingleSpacedRow>
        <StyledContentLeft isMobile={isMobile}>
          <Typography>{lineItemPrettyDescription}</Typography>
        </StyledContentLeft>
        <StyledContentRight isMobile={isMobile}>
          <span></span>
        </StyledContentRight>
      </SingleSpacedRow>
      {showLineItemDetailModal && (
        <Modal
          hideIcon={true}
          title="Detailed information"
          onClose={() => setShowLineItemDetailModal(false)}
        >
          <Typography
            size={20}
            fontWeight={"medium"}
            style={{ marginBottom: 24 }}
          >
            Technical information
          </Typography>
          <Typography
            style={{ marginBottom: 24 }}
            color={tokens.TINTS_OFF_WHITE_20}
          >
            {lineItem.line_item_description}
          </Typography>
          <Typography
            size={20}
            fontWeight="medium"
            style={{ marginBottom: 24 }}
          >
            Instructions
          </Typography>
          <Typography color={tokens.TINTS_OFF_WHITE_20}>
            {lineItem.line_item_instructions}
          </Typography>
        </Modal>
      )}
    </SowLineItem>
  );
};

const WorkItemAccordion = ({
  workItem,
  isMobile,
}: WorkItemsProps): React.JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [fullImageIndex, setFullImageIndex] = useState(-1);

  const { sow } = useCapContext();

  const mapItemTypeToTagName = (type: WorkType) => {
    const tagItemMap = {
      Electrical: "Electrical",
      Roofing: "Roof",
      Structural: "Structural",
      Misc: "Miscellaneous",
      "Post Install Repair": "",
    };
    return tagItemMap[type];
  };

  const lineItemImages = sow?.allSharinPixImages.filter((image: any) => {
    return (
      image.sharinpix__DisplayTags__c?.includes("CAP Design Summary") &&
      (image.sharinpix__Tags__c?.includes(
        mapItemTypeToTagName(workItem?.type ?? "")
      ) ||
        image.sharinpix__DisplayTags__c?.includes(
          mapItemTypeToTagName(workItem?.type ?? "")
        ))
    );
  });

  const openLargeImageCarousel = useCallback((index: number) => {
    setFullImageIndex(index);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const slides = lineItemImages?.map((image: any, index: number) => (
    <Slide imageUrl={image.sharinpix__ImageURLFull__c} key={index}>
      {fullImageIndex === -1 && (
        <RelativeContainer>
          <AbsoluteBrandBlueButton
            onClick={() => openLargeImageCarousel(index)}
          >
            <SvgIcon
              width={24}
              height={24}
              name={SvgNames.ArrowsOutSimple}
              color={tokens.TINTS_OFF_WHITE_90}
            />
          </AbsoluteBrandBlueButton>
        </RelativeContainer>
      )}
    </Slide>
  ));

  return (
    <FullPanel>
      <PanelHeader underline={true}>
        <StyledContentLeft isMobile={isMobile}>
          <Typography size={24} fontWeight={"regular"} lineHeight={31}>
            {workItem.type}
          </Typography>
        </StyledContentLeft>
        <StyledContentRight isMobile={isMobile}>
          <Button
            onClick={() => setIsExpanded(true)}
            style={{
              background: "none",
              padding: 0,
              display: `${!isExpanded ? "flex" : "none"}`,
            }}
          >
            <SvgIcon
              height={30}
              width={30}
              color={tokens.BRAND_HERO_BLUE}
              name={SvgNames.ChevronDown}
            />
          </Button>
          <Button
            onClick={() => setIsExpanded(false)}
            style={{
              background: "none",
              padding: 0,
              display: `${isExpanded ? "flex" : "none"}`,
            }}
          >
            <SvgIcon
              height={30}
              width={30}
              color={tokens.BRAND_HERO_BLUE}
              name={SvgNames.ChevronUp}
            />
          </Button>
        </StyledContentRight>
      </PanelHeader>
      {isExpanded && (
        <Panel>
          {workItem.lineItems.map((item, index) => (
            <WorkLineItem lineItem={item} key={index} isMobile={isMobile} />
          ))}
          {slides && slides.length > 0 && (
            <SimpleCarousel
              options={{ loop: true }}
              slides={slides}
              showDots={true}
            />
          )}
        </Panel>
      )}
      {slides && fullImageIndex > -1 && (
        <CarouselModal
          customModalWidth={visualViewport?.width ?? 100}
          hideClose={true}
        >
          <RowRightContent>
            <CloseCarouselButton onClick={() => setFullImageIndex(-1)}>
              <SvgIcon
                height={20}
                width={20}
                color={tokens.TINTS_OFF_WHITE_80}
                name={SvgNames.Close}
              />
            </CloseCarouselButton>
          </RowRightContent>
          <FullSizeCarousel
            requestedIndex={fullImageIndex}
            slides={slides}
            options={{ inViewThreshold: 1 }}
            showDots={true}
          />
        </CarouselModal>
      )}
    </FullPanel>
  );
};

const HomeReadinessStepView: React.FC = () => {
  const { sow } = useCapContext();
  const isMobile = useMediaQuery({ maxWidth: 750 });
  const isTablet = useMediaQuery({ maxWidth: 1024 });

  let filteredWorkItems: WorkItem[] = [];

  sow?.workItems.forEach((workItem: WorkItem) => {
    if (workItem.lineItems.length > 0) {
      filteredWorkItems.push(workItem);
    }
  });

  return (
    <>
      {sow && (
        <>
          <Typography
            color={tokens.BRAND_HERO_BLUE}
            size={isTablet ? 24 : 32}
            lineHeight={isTablet ? 32 : 38}
            style={{
              marginTop: isMobile ? 16 : 24,
              marginBottom: isMobile ? 16 : 24,
            }}
          >
            Getting your home ready for solar
          </Typography>

          {filteredWorkItems.map((item) => {
            return (
              <WorkItemAccordion
                workItem={item}
                key={item.type.toLowerCase()}
                isMobile={isMobile}
              />
            );
          })}
          <SummaryRowContainer isTablet={isTablet}>
            <SummaryRow
              style={{ paddingTop: "8px", borderTop: "1px solid #1F2647" }}
            >
              <StyledContentLeft isMobile={isMobile}>
                <Typography fontWeight={"regular"} size={20}>
                  Subtotal work needed
                </Typography>
              </StyledContentLeft>
              <StyledContentRight isMobile={isMobile}>
                <Typography color={"#6F6D68"} fontWeight={"medium"} size={24}>
                  {formatCurrency(sow.priceOfAllWork + sow.totalTax, 2)}
                </Typography>
              </StyledContentRight>
            </SummaryRow>
            <SummaryRow>
              <StyledContentLeft isMobile={isMobile}>
                <Typography color={"#C37354"} fontWeight={"medium"} size={20}>
                  Sunrun subsidy
                </Typography>
              </StyledContentLeft>
              <StyledContentRight isMobile={isMobile}>
                <Typography color={"#C37354"} fontWeight={"medium"} size={20}>
                  -{formatCurrency(sow.totalSubsidy, 2)}
                </Typography>
              </StyledContentRight>
            </SummaryRow>
            <SummaryRow>
              <StyledContentLeft isMobile={isMobile}>
                <Typography fontWeight={"regular"} size={24}>
                  Total cost to customer
                </Typography>
              </StyledContentLeft>
              <StyledContentRight isMobile={isMobile}>
                <Typography fontWeight={"regular"} size={24}>
                  {formatCurrency(sow.priceToCustomer, 2)}
                </Typography>
              </StyledContentRight>
            </SummaryRow>
          </SummaryRowContainer>
        </>
      )}
    </>
  );
};

const FullPanel = styled.section`
  margin-bottom: 15px;
`;

const SunrunChip = styled.span<{ isMobile: boolean }>`
  line-height: 20px;
  height: 22px;
  margin: ${({ isMobile }) => (isMobile ? "6px 0" : "0")};
  width: ${({ isMobile }) => (isMobile ? "110px" : "126px")};
  min-width: ${({ isMobile }) => (isMobile ? "110px" : "126px")};
  padding: ${({ isMobile }) => (isMobile ? "0px 6px" : "2px 8px")};
  border-radius: 4px;
  border: 1px solid ${tokens.BRAND_HERO_BLUE};
  display: flex;
  align-items: center;
  > p {
    font-size: ${({ isMobile }) => (isMobile ? "14px" : "16px")};
  }
`;

const CustomerChip = styled.span<{ isMobile: boolean }>`
  line-height: 20px;
  height: 22px;
  margin: ${({ isMobile }) => (isMobile ? "6px 0" : "0")};
  width: ${({ isMobile }) => (isMobile ? "128px" : "146px")};
  min-width: ${({ isMobile }) => (isMobile ? "128px" : "146px")};
  padding: ${({ isMobile }) => (isMobile ? "0px 6px" : "2px 8px")};
  border-radius: 4px;
  background-color: ${tokens.BRAND_HERO_BLUE};
  display: flex;
  align-items: center;
  > p {
    font-size: ${({ isMobile }) => (isMobile ? "14px" : "16px")};
  }
`;

const PanelHeader = styled(Row)`
  background: ${tokens.TINTS_OFF_WHITE_90};
  border-radius: 8px 8px 0 0;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
  width: auto;
  padding: 20px 24px;
`;

const Panel = styled.div`
  background: ${tokens.TINTS_OFF_WHITE_90};
  padding: 16px 24px;
  border-radius: 0 0 8px 8px;
`;

const Slide = styled.div<{
  imageUrl: string;
}>`
  aspect-ratio: 3/2;
  ${({ imageUrl }) =>
    imageUrl ? `background: url(${imageUrl}) no-repeat center;` : ""}
  background-size: cover;
  border-radius: 8px;
  overflow: hidden;
`;

const SummaryRowContainer = styled.div<{ isTablet: boolean }>`
  padding-bottom: ${({ isTablet }) => (isTablet ? "40px" : "0")};
`;

const SummaryRow = styled(Row)`
  padding: 0;
`;

const SingleSpacedRow = styled(Row)`
  padding-top: 0;
  padding-bottom: 0;
`;

const SowLineItem = styled.section`
  margin-bottom: 18px;
`;

const StyledContentLeft = styled(ContentLeft)<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? "100%" : "inherit")};
`;

const StyledContentRight = styled(ContentRight)<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? "0%" : "inherit")};
`;

const LineItemLeft = styled(ContentLeft)<{ isMobile: boolean }>`
  display: flex;
  gap: 10px;
  width: ${({ isMobile }) => (isMobile ? "100%" : "60%")};
`;

const LineItemRight = styled(ContentRight)<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? "10%" : "40%")};
  align-items: center;
`;

const ClearButton = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  margin-top: 2px;
`;

const ExpandImageButtonRow = styled(Row)`
  width: unset;
  right: 8px;
`;

const AbsoluteBrandBlueButton = styled(Button)`
  background: ${tokens.BRAND_HERO_BLUE};
  border: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 2px;
  position: absolute;
  top: 8px;
  right: 8px;
`;

const BrandBlueButton = styled(Button)`
  background: ${tokens.BRAND_HERO_BLUE};
  border: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 2px;
  margin: 0;
  margin-right: 8px;
`;

const CarouselModal = styled(Modal)`
  margin: 0;
  background: rgba(0, 0, 0, 0.8);

  .modal-container {
    top: 0;
    width: 100%;
    height: 100%;

    border: 0;
    background-color: rgba(0, 0, 0, 0.8);

    & > div {
      padding: 0;
      background-color: transparent;
    }
  }

  .modal-content-container {
    background-color: transparent;

    margin-bottom: 32px;
  }
`;

const CloseCarouselButton = styled(Button)`
  background: transparent;
  border: 0;
  color: white;
`;

export {
  HomeReadinessStepView,
  BrandBlueButton,
  ExpandImageButtonRow,
  Slide,
  CarouselModal,
  CloseCarouselButton,
};
